import { Component } from '@angular/core';
import { QuestionsDataService } from 'app/modules/questions/service/data/question-data.service';
import { BasePage, RequestHandlerOptions } from 'app/shared/classes';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-question-item-popup',
  templateUrl: './question-item-popup.component.html',
  styleUrls: ['./question-item-popup.component.scss'],
})
export class QuestionItemPopupComponent extends BasePage {
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private questionsDataService: QuestionsDataService
  ) {
    super();
  }

  onSubmit(data) {
    this.questionsDataService.create(data, this.apiOptions).subscribe((res) => {
      this.ref.close(res.data);
    });
  }
}

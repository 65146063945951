import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { QuestionsDataService } from 'app/modules/questions/service/data/question-data.service';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import {
  DataTypeEnum,
  DynamicFieldConfig,
  IAction,
  McqSelectionModes,
  ModuleKeywords,
  QuestionnaireQuestionsDto,
  RequestHandlerOptions,
  getEnumOptions,
  getProp
} from 'app/shared/classes';
import { BaseForm } from 'app/shared/classes/view/BaseForm';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { QuestionItemPopupComponent } from '../question-popup/question-item-popup.component';

@Component({
  selector: 'app-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrls: ['./questionnaire-form.component.scss'],
})
export class QuestionnaireFormComponent extends BaseForm<QuestionnaireQuestionsDto> implements OnInit {

  scoringTypes = getEnumOptions(QuestionnaireQuestionsDto.ScoringTypeEnum)
  scoredType = QuestionnaireQuestionsDto.ScoringTypeEnum.Scored;

  @Input()
  itemId: string;

  @Input()
  editMode: Boolean = false;

  @Input()
  showQuestions: Boolean = true;

  showAddQuestion: Boolean = false;

  allQuestionsUnScored: Boolean = true;

  showAddQuestionAction: IAction = {
    id: 1,
    label: 'Add Question',
    buttonType: 'button',
    command: this.onSearchQuestionClick.bind(this),
    icon: 'pi pi-plus',
    color: 'info',
  };
  minPassingScore = 0;
  maxPassingScore = 0;

  onSearchQuestionClick(value) {
    this.dialogService.showDialog(
      QuestionItemPopupComponent,
      'Add Question',
      (e) => {
        this.searchQ = '';
        this.showAddQuestion = false;
        this.setData({ ...this?.data, questions: [...this.tableQuestions, e] });
      },
      {
        data: {
          question: value,
        },
      }
    );
  }

  setData(data: any) {
    if (data) {
      // if (data?.questions) {
      //     this.tableQuestions = Object.keys(this.data.questions).map(key => {
      //         return this.data.questions[key]
      //     })
      //     // this.tableQuestions = [...this.data.questions];
      // }
      this.data = data;
      this.tableQuestions = data?.questions
        ? data?.questions?.map((x) => {
          return { ...x, fieldDtos: x?.fields };
        })
        : [];
      this._questions = data?.questions ? [...data?.questions] : [];
      this.formGroup.patchValue({ ...data });
      this.calcMinMaxPassingScore();
    }
  }

  selectedQuestions = [];
  tableQuestions = [];
  _questions = [];
  searchQ;

  actions: {
    id: any;
    name: string;
    icon: string;
    type: any;
    color: string;
  }[];

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  @Input() set questions(q: any[]) {
    if (q) {
      this._questions = [...q];
    }
  }

  get questions() {
    return this._questions;
  }

  cols: any[];

  searchQuestions$: Observable<any[]>;

  private searchText$ = new Subject<string>();

  constructor(
    private questionDataService: QuestionsDataService,
    private questionnaireDataService: QuestionnaireDataService,
    public dialogService: AppDialogService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.Questionnaire);

    this._initActions();
  }

  private _initActions(): void {
    this.actions = [
      {
        id: PageActionsEnum.Delete,
        name: 'Delete',
        icon: 'heroicons_solid:trash',
        type: ActionTypesEnum.Delete,
        color: 'warn',
      },
    ];
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'question', header: 'Question', viewType: DataTypeEnum.Text },
      // { field: "dynamicField.type", header: "Type",viewType:DataTypeEnum.Badge },
    ];

    this.editMode ? this.patchFormFromApi() : null;
  }

  patchFormFromApi() {
    this.questionnaireDataService.getByIdOrCode(this.itemId, this.apiOptions).subscribe((res: any) => {
      this.data = res.data;
      this._patchValues();
    });
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.getChangedFormValues(this.formatGetData()).updateItems;
  }
  formatGetData() {
    let qs = {};
    this.tableQuestions.forEach((element) => {
      qs[element?.dynamicField?.name] = element;
    });
    let data: QuestionnaireQuestionsDto = {
      // id: this.data?.id,
      // code: this.data?.code,
      name: this.formGroup.value.name,
      description: this.formGroup.value.description,
      questions: this.tableQuestions.map((x) => x.code),
      type: this.formGroup.getRawValue().type,
      passScore: this.formGroup.getRawValue().passScore,
      scoringType: this.formGroup.getRawValue().scoringType,
    };
    return data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      questions: new FormControl([], [Validators.required, Validators.minLength(1)]),
      type: new FormControl(null, Validators.required),
      scoringType: new FormControl(QuestionnaireQuestionsDto.ScoringTypeEnum.NotScored),
      passScore: new FormControl(null),
    });
  }

  _patchValues() {
    this.formGroup.patchValue({
      ...this.data,
    }, { emitEvent: false });
    if (this.data?.questions) {
      this.tableQuestions = Object.keys(this.data.questions).map((key) => {
        return { ...this.data.questions[key], fieldDtos: this.data.questions[key]?.fields };
      });
      this.calcMinMaxPassingScore();
      // this.tableQuestions = [...this.data.questions];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.disabled) {
      this._patchValues();
    }
  }

  ngAfterViewInit(): void {
    this._setupSubscriptions();
    this.onFilterInputChange();
  }

  onChangeQuestions(questions) {
    this.tableQuestions = [...questions.value];
    this.calcMinMaxPassingScore();
  }

  onFilterInputChange() {
    this.searchText$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((keywords) => {
          return this.questionDataService.searchByQuestion(keywords);
        })
      )
      .subscribe((res) => {
        if (res.content.length <= 0) {
          this.showAddQuestion = true;
        } else {
          this.showAddQuestion = false;
        }

        this.searchQuestions$ = of(res.content);
      });
  }

  getSearchValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(keywords: string) {
    this.searchText$.next(keywords);
  }
  onTargetCodeSelectorChanges(event) {
    //loading
    console.log('onTargetCodeSelectorChanges', event);
    this.tableQuestions = event;
    // this.formGroup?.patchValue({
    //   questions: this.tableQuestions,
    // });
    this.calcMinMaxPassingScore();
  }
  onSelectItem(event) {
    this.tableQuestions.push(event?.value);
    this.formGroup?.patchValue({
      questions: this.tableQuestions,
    });

    this.searchQ = '';
    this.calcMinMaxPassingScore();
  }
  calcMinMaxPassingScore() {
    let total = 0;
    let minTotal = 0;
    let maxTotal = 0;
    this.allQuestionsUnScored = true;
    this.tableQuestions?.forEach((qst) => {
      (qst?.fieldDtos ?? qst?.fields)?.forEach((field) => {
        if (field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == McqSelectionModes.scored) {
          let minVal = Infinity;
          let localMax = 0;
          field?.options?.[DynamicFieldConfig.MCQ_SELECTION_OPTIONS]?.forEach((element) => {
            total += element?.value;
            if (element?.value < minVal) {
              minVal = element?.value;
            }
            if (element?.value > localMax) {
              localMax = element?.value;
            }
          });
          maxTotal += localMax;
          minTotal += minVal;
          this.allQuestionsUnScored = false;
        }
      });
    });
    this.formGroup.controls.scoringType.patchValue(this.allQuestionsUnScored ? QuestionnaireQuestionsDto.ScoringTypeEnum.NotScored : QuestionnaireQuestionsDto.ScoringTypeEnum.Scored);
    this.maxPassingScore = maxTotal;
    this.minPassingScore = minTotal;
  }
  onActionClick(action: any): void {
    switch (action.action.id) {
      case PageActionsEnum.Delete:
        this._delete(action.index);
        break;
      default:
        break;
    }
  }

  _delete(id) {
    this.tableQuestions.splice(id, 1);
    this.formGroup?.patchValue({
      questions: this.tableQuestions,
    });
    this.calcMinMaxPassingScore();
  }
  getElementValue(key: string, data: any) {
    let value = data[key];
    value = getProp(data, key);
    return value;
  }
}

enum PageActionsEnum {
  Delete,
}
enum ActionTypesEnum {
  Add = 0,
  update = 1,
  Delete = 2,
  Activate = 3,
  Deactivate = 4,
  customAction = 5,
  switch = 6,
}
